// src/Login.js
import React, { useState, useEffect } from 'react';
import './Login.css'; // You can create and style this CSS file as needed
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword  } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { getFirestore } from "firebase/firestore";
import { collection, query, where, getDocs,doc, getDoc, setDoc, deleteDoc, addDoc } from "firebase/firestore";
import axios from 'axios';
import cronFormat from './bilder/cron.PNG';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDGbABbjTVMwKi06cST9rb_tzixe41KMt8",
  authDomain: "aihr-d0ada.firebaseapp.com",
  projectId: "aihr-d0ada",
  storageBucket: "aihr-d0ada.appspot.com",
  messagingSenderId: "44659104993",
  appId: "1:44659104993:web:1e1ab505adbe240e1e6ba0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function Login() {  
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [logedIn, setLogedIn] = useState(false);
    const [companyCode, setCompanyCode] = useState('');
    const [cron, setCron] = useState('');
    const [tool, setTool] = useState('');
    const [newCron, setNewCron] = useState('')
    const [toEmail, setToEmail] = useState('')
    const [newtoEmail, setNewToEmail] = useState('')
    const [users, setUsers] = useState([]);
    const [delUser, setDelUser] = useState([]);

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [newSubject, setNewSubject] = useState('');
    const [newCompanyCode, setNewCompanyCode] = useState('');
    const [newCompanyEmail, setNewCompanyEmail] = useState('');
    const [newCopmanyCron, setNewCompanyCron] = useState('');
    const [newCopmanyUsers, setNewCompanyUsers] = useState('');
    const [selectedTools, setSelectedTools] = useState([]);
    const [toolIds, setToolIds] = useState(['chat', 'rapport']); // Replace with actual tool IDs
   
    const handleToolCheckboxChange = (event, toolId) => {
      if (event.target.checked) {
          setSelectedTools([...selectedTools, toolId]);
          console.log("test: ",selectedTools)
      } else {
          setSelectedTools(selectedTools.filter(id => id !== toolId));
          console.log(selectedTools)
      }
  };


    const auth = getAuth(app);
    const navigate = useNavigate();

    const logOut = () => {
      navigate('/');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log('Logged in as:', user);
            setError('Logged in as:', email);
            setLogedIn(true)
        })
        .catch((error) => {
            setError(error.message);
        });
    };

    const getSchedule = async () => {
      try {
          const scheduleDocRef = doc(db, 'companyData', companyCode);
          
          const docSnapshot = await getDoc(scheduleDocRef);
          if (docSnapshot.exists()) {
              console.log(docSnapshot.id, " => ", docSnapshot.data());
              const scheduleData = docSnapshot.data();
              setCron(scheduleData.cron);
              setToEmail(scheduleData.to)
              setSelectedTools(scheduleData.tool.split(', '))

              const usersQuery = query(collection(db, 'Users'), where('personalCode', '==', companyCode));
                const querySnapshot = await getDocs(usersQuery);

                const usersList = querySnapshot.docs.map(doc => doc.data());
                setUsers(usersList);
          } else {
              console.log("No such document!");
          }
      } catch (error) {
          console.error("Error getting schedule:", error);
      }
  };

  const deleteUser = async () => {
    try {
      const userQuery = query(collection(db, 'Users'), where('personalCode', '==', companyCode), where('PassCode', '==', delUser));
      const querySnapshot = await getDocs(userQuery);
      
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (docSnapshot) => {
          await deleteDoc(doc(db, 'Users', docSnapshot.id));
          console.log(`User with ID ${docSnapshot.id} deleted successfully`);
        });
        // Refresh users list
        const updatedUsersQuery = query(collection(db, 'Users'), where('personalCode', '==', companyCode));
        const updatedQuerySnapshot = await getDocs(updatedUsersQuery);
        const updatedUsersList = updatedQuerySnapshot.docs.map(doc => doc.data());
        setUsers(updatedUsersList);
      } else {
        console.log('No user found with the specified personalCode and PassCode.');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  
  const sendEmail = (subject, text) => {
    axios.get('https://sleepy-hamlet-15893-b0b9da7c499e.herokuapp.com/send-email', {
      params: {
        subject: subject,
        text: text
      }
    })
    .then(response => {
      console.log('Email sent successfully');
      // Provide UI feedback to the user
    })
    .catch(error => {
      console.error('Error sending email:', error);
      // Provide UI feedback to the uer
    });
  };
  

  const setNewSchedule = async () => {
    try {
        const scheduleDocRef = doc(db, 'companyData', companyCode);

        // Update the document with new day and time
        await setDoc(scheduleDocRef, {
            cron: newCron,
        }, { merge: true });

        console.log("New schedule saved successfully!");
    } catch (error) {
        console.error("Setting new schedule:", error);
    }
};
const setNewMail = async () => {
  try {
      const scheduleDocRef = doc(db, 'companyData', companyCode);

      // Update the document with new day and time
      await setDoc(scheduleDocRef, {
          to: newtoEmail,
      }, { merge: true });

      console.log("New schedule saved successfully!");
  } catch (error) {
      console.error("Setting new schedule:", error);
  }
};
const updateTool = async () => {
  try {
      const scheduleDocRef = doc(db, 'companyData', companyCode);

      // Update the document with new day and time
      await setDoc(scheduleDocRef, {
          tool: selectedTools.join(', '),
      }, { merge: true });
      setSelectedTools([]);
      console.log("New schedule saved successfully!");
  } catch (error) {
      console.error("Setting new schedule:", error);
  }
};

const addNewCompany = async (event) => {
  event.preventDefault();
  try {
      await setDoc(doc(db, 'companyData', newCompanyCode), {
          subject: newSubject,
          cron: newCopmanyCron,
          text: "",
          to: newCompanyEmail,
          tool: selectedTools.join(', '),
      });
      console.log('New company added successfully');
      setSelectedTools([])
      setNewSubject('')
      setNewCompanyCode('')
      setNewCompanyEmail('')
      setNewCompanyCron('')
      setIsPopupVisible(false); // Hide the popup after successful submission
  } catch (error) {
      console.error('Error adding new company:', error);
  }
};
const addNewUser = async (event) => {
  event.preventDefault();
  try {
    let newUserCode;
    let isUnique = false;

    // Generate and check for unique PassCode
    while (!isUnique) {
      newUserCode = Math.floor(Math.random() * 9000) + 1000;
      console.log('Generated Code:', newUserCode);

      const userQuery = query(collection(db, 'Users'), where('PassCode', '==', newUserCode));
      const querySnapshot = await getDocs(userQuery);

      if (querySnapshot.empty) {
        isUnique = true;
      } else {
        console.log('Code exists, generating a new one...');
      }
    }

    // Add new user with the unique PassCode
    await addDoc(collection(db, 'Users'), {
      personalCode: companyCode,
      PassCode: newUserCode.toString(),
    });

    console.log('New user added successfully with PassCode:', newUserCode);

  } catch (error) {
    console.error('Error adding new user:', error);
  }
};



   return (
    <div className="container">
        {!logedIn ? (
              <div className="login-container">
              <h2>Login</h2>
              {error && <p className="error">{error}</p>}
              <form className="login-form" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="email">Email:</label>
                  <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required 
                  />
                </div>
                <div>
                  <label htmlFor="password">Password:</label>
                  <input 
                    type="password" 
                    id="password" 
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required 
                  />
                </div>
                <button type="submit">Login</button>
              </form>
            </div>
      ) : (
        <div className="LoggedInPage">
            <h2>Logged in</h2>
            <button onClick={logOut}>Logout</button>
            <button onClick={() => {setIsPopupVisible(!isPopupVisible); setSelectedTools([]);}}>Create a new company</button>
            <div>
            <label htmlFor="companyCode">Company Code: </label>
              <input 
                type="text" 
                id="companyCode" 
                name="companyCode"
                value={companyCode}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,4}$/.test(value)) {
                    setCompanyCode(value);
                  }
                }}
                maxLength="4"
                pattern="\d*"
                required 
              />
              <button onClick={getSchedule}>See data</button>
              </div>
          <div className="adminPanelHolder">
            <div className="RaportInput">
            <label htmlFor="newCron">scedule and Email..</label>
              {cron && toEmail && (
                <div>
                  <p>To: {toEmail}</p>
                  <p>Cron: {cron}</p>
                </div>
              )}
              <img src={cronFormat} alt="cron format" style={{ width: '30vw', height: 'auto', paddingLeft: '30px' }} />
              <div>
                <label htmlFor="newtoEmail">New To Email: </label>
                <input 
                  type="text" 
                  id="newtoEmail" 
                  name="newtoEmail"
                  value={newtoEmail}
                  onChange={(e) => setNewToEmail(e.target.value)}
                  required 
                />
                <button onClick={setNewMail}>Set New Email</button>
              </div>
              <div>
                <label htmlFor="newCron">New Cron: </label>
                <input 
                  type="text" 
                  id="newCron" 
                  name="newCron"
                  value={newCron}
                  onChange={(e) => setNewCron(e.target.value)}
                  required 
                />
                <button onClick={setNewSchedule}>Set New Schedule</button>
              </div>
              <div>
                <label>Tools:</label>
                {toolIds.map((toolId) => (
                    <div key={toolId}>
                        <input 
                            type="checkbox" 
                            id={`tool_${toolId}`} 
                            name="tools"
                            checked={selectedTools.includes(toolId)}
                            onChange={(e) => handleToolCheckboxChange(e, toolId)}
                        />
                        <label htmlFor={`tool_${toolId}`}>{toolId}</label>
                    </div>
                ))}
              </div>
              <button onClick={updateTool}>Update Tool</button>
              <button onClick={() => sendEmail('Subject here', 'Email text here')}>Send Email</button>
            </div>
          
            <div className="userPanel">
            <label>Users: {users.length}</label>
              {users.length > 0 ? (
                <div className="user-list-container">
                  <ul className="user-list">
                    {users.map(user => (
                      <li key={user.id} className="user-item">
                        <p><strong>Email:</strong> {user.email}</p>
                        <p><strong>UserID:</strong> {user.PassCode}</p>
                        {/* Add more fields as needed */}
                        <hr />
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p>No users found with this company code.</p>
              )}
                            <div>
                <label >user to delete </label>
                <input 
                  type="text" 
                  id="delUser" 
                  name="delUser"
                  value={delUser}
                  onChange={(e) => setDelUser(e.target.value)}
                  required 
                />
                <button onClick={deleteUser}>Delete</button>
                <button onClick={addNewUser}>Add new user</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPopupVisible && (
                <div className="popup">
                    <div className="popup-content">
                        <h3>Add New Company</h3>
                        <form onSubmit={addNewCompany}>
                            
                            <div>
                                <label htmlFor="newCompanyCode">Code: </label>
                                <input 
                                    type="text" 
                                    id="newCompanyCode" 
                                    name="newCompanyCode"
                                    value={newCompanyCode}
                                    onChange={(e) => setNewCompanyCode(e.target.value)}
                                    required 
                                />
                            </div>
                            <div>
                                <label htmlFor="newCompanyName">Subject: </label>
                                <input 
                                    type="text" 
                                    id="newSubject" 
                                    name="newSubject"
                                    value={newSubject}
                                    onChange={(e) => setNewSubject(e.target.value)}
                                    required 
                                />
                            </div>
                            <div>
                                <label htmlFor="newCompanyEmail">Email: </label>
                                <input 
                                    type="email" 
                                    id="newCompanyEmail" 
                                    name="newCompanyEmail"
                                    value={newCompanyEmail}
                                    onChange={(e) => setNewCompanyEmail(e.target.value)}
                                    required 
                                />
                            </div>
                            <div>
                                <label htmlFor="newCompanyEmail">Cron: </label>
                                <input 
                                    type="text" 
                                    id="newCopmanyCron" 
                                    name="newCopmanyCron"
                                    value={newCopmanyCron}
                                    onChange={(e) => setNewCompanyCron(e.target.value)}
                                    required 
                                />
                            </div>
                            <div>
                              <label>Tools:</label>
                                {toolIds.map((toolId) => (
                                    <div key={toolId}>
                                        <input 
                                            type="checkbox" 
                                            id={`tool_${toolId}`} 
                                            name="tools"
                                            value={toolId}
                                            onChange={(e) => handleToolCheckboxChange(e, toolId)}
                                        />
                                        <label htmlFor={`tool_${toolId}`}>{toolId}</label>
                                    </div>
                                ))}
                            </div>
                            <button onClick={addNewCompany}>Add Company</button>
                            <button type="button" onClick={() => setIsPopupVisible(false)}>Cancel</button>
                        </form>
                    </div>
                </div>
            )}
    </div>
  );
}

export default Login;
