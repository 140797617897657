import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Phonesnr1 from "../src/bilder/Phones1.png";
import scroll from "../src/bilder/Scroll.png";
import applestore from "../src/bilder/appstore.png";
import playstore from "../src/bilder/googleplay.png";
import Phone2 from "../src/bilder/Phones2.png";
import Phone3 from "../src/bilder/Phone.png";
import Login from './logIn';

function MainPage() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true); // State to manage overlay visibility

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="Container">
      {isOverlayVisible && (
        <div className="grayScreenOverlay">
          <p className="grayText">
            Welcome to AIHR!<br />
            This site is currently under development and will be available soon.<br />
            For any inquiries, please email us at <a href="mailto:amdevelopment@aihr.no">amdevelopment@aihr.no</a>.
          </p>
        </div>
      )}
    <div className={`grayScreen ${isOverlayVisible ? 'disabled' : ''}`}>
        <header className="hero" id="hero">
          <div className="topBanner">
            <p className="sitename" onClick={() => scrollToElement("hero")}>
              AIHR
            </p>
            <div className="tabs">
              <p onClick={() => scrollToElement("midmid")}>
                Utforsk
              </p>
              <p onClick={() => scrollToElement("bottom")}>
                Om oss
              </p>
            </div>
            <div className="contactbtn">
              <p>
                Contact
              </p>
            </div>
          </div>
          <div className="herocontent">
            <div className="col1">
              <p className="title">
                AIHR
              </p>
              <p className="p1Text">
              Velkommen til AI HR-applikasjonen! Denne innovative appen utnytter kunstig intelligens (AI) for å revolusjonere HR-feltet. Enten din bedrift har HR-avdeling eller ikke, tilbyr denne appen verktøy for å effektivisere HR-prosesser og forbedre beslutningstaking.
              </p>
              <div className="shopbtns">
                <p onClick={() => scrollToElement("bottom")}>
                  <img src={applestore} alt="App Store" className="" />
                </p>
                <p onClick={() => scrollToElement("bottom")}>
                  <img src={playstore} alt="Play Store" className="" />
                </p>
              </div>
            </div>
            <div className="col2">
              <img src={Phonesnr1} alt="Phones with the app" className='firstPhones'/>
            </div>
          </div>
          <img src={scroll} alt="scroll icon" className={isScrolled ? "scrollPicRemove" : "scrollPic"} />
        </header>
        <div className="midPage">
          <div className="midTop">
            <p className="textInfo">
              Help keep your employees<br />motivated
            </p>
            <p className="textInfo">
              Give your HR department<br />the right tools
            </p>
            <p className="textInfo">
              Boost<br />productivity
            </p>
          </div>
          <div className="midmid" id='midmid'>
            <div className="col1mid">
              <p className="subtitle">
                Personlig klageboks i lommen
              </p>
              <p className="p2Text">
                Everyone deserves their dream vaction.
                Discover the world’s most refreshing stops and indulge in the ultimate luxury.
              </p>
            </div>
            <div className="col2mid">
              <img src={Phone2} alt="Phones with the app" className='firstPhones'/>
            </div>
          </div>
          <div className="mid2">
            <div className="col1mid">
            <p>
              <img src={Phone3} alt="Phones with the app" className='secondPhones'/>
            </p>
            </div>
            <div className="col2mid">
            <p>
            <p className="subtitle">
                Personlig klageboks i lommen
              </p>
              <p className="p2Text">
              Everyone deserves their dream vaction.
              Discover the world’s most refreshing stops and indulge in the ultimate luxury.
              </p>
            </p>
            </div>
          </div>
        </div>
        <div className="bottomBoder">
          <p className="bottomtext" id='bottom'>
            Add the AI to your HR team!
          </p>
          <div className="shopbtnsbottom">
            <p onClick={() => scrollToElement("bottom")}>
              <img src={applestore} alt="App Store" className="" />
            </p>
            <p onClick={() => scrollToElement("bottom")}>
              <img src={playstore} alt="Play Store" className="" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
